<template>
  <a-drawer
    wrapClassName="task-drawer"
    width="38.5vw"
    title="选择人员"
    :visible="visible"
    @update:visible="(val) => $emit('update:visible', val)"
  >
    <div class="header">
      <div class="user">
      </div>
    </div>
    <div>
      <div class="search">搜索</div>
      <a-select
        class="add-task-select"
        v-model:value="taskUserSelectList"
        placeholder="请选择"
        allowClear
        show-search
        mode="multiple"
        :maxTagCount="1"
        :disabled="isSelectDisable"
        @select="handleUserSelect"
      >
        <a-select-option
          v-for="item in taskUserOps"
          :value="
            item.fullName +
            '/' +
            item.email +
            item.fullName.replace(', ', '') +
            item.fullName.replace(', ', ',')
          "
          :key="item.id"
          :disabled="item.flag"
          class="add-task-select-option"
        >
          <div class="option">
            <div class="name">{{ item.fullName + " " + item.email }}</div>
            <div class="onboardDate">入职时间: {{ item.onboardDate }}</div>
            <div class="terminationDate">离职时间: {{ item.terminationDate ? item.terminationDate : "-" }}</div>
          </div>
        </a-select-option>
      </a-select>
      <div class="task-user-list">
        <CardTitle size="large">选中人员预览</CardTitle>
        <a-table
          :columns="taskUserCols"
          :data-source="taskUserList"
          rowKey="id"
          :scroll="{
                y: 500,
                scrollToFirstRowOnChange: true,
              }"
          :pagination="false"
        >
          <template #operation="{ record }">
            <a-space>
              <a class="redLink underline" @click="delUserClick(record)">删除</a>
            </a-space>
          </template>
        </a-table>
      </div>
    </div>
    <div class="bottom-btn">
      <a-space>
        <a-button type="primary" ghost @click="handleCancel">上一步</a-button>
        <a-button type="primary" @click="handleSave">保存</a-button>
      </a-space>
    </div>
    <OverlapModal
      :data="overlapData"
      :taskType="task.taskType"
      @cancel="delUserClick(selectUser)"
      v-model:visible="overlapModalVisible"
    />
  </a-drawer>
</template>

<script>
import CardTitle from "@/components/CardTitle";
import { computed, defineComponent, ref } from "vue";
import { onMounted } from "@vue/runtime-core";
import { useGlobalPropertyHook } from "@/hooks/common";
import { message } from "ant-design-vue";
import OverlapModal from "@/components/OverlapModal";
export default defineComponent({
  name: "AddPersonDrawer",
  components: {
    OverlapModal,
    CardTitle
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    data: {
      type: Object,
      default: () => ({
        status: "prohibit",
        assignmentList: [],
        workTaskList: []
      })
    },
    task: {
      type: Object,
      default: () => ({})
    }
  },
  setup(props, { emit }) {
    const { $api } = useGlobalPropertyHook();

    const taskUserSelectList = ref([]);
    const taskUserOps = ref([]);

    const getConsultantOptions = () => {
      const params = {
        beginDate: props.task.beginDate,
        endDate: props.task.endDate
      };
      $api.getConsultantList(params).then(res => {
        taskUserOps.value = res.data;
      });
    };

    const taskUserList = computed(() => {
      if (taskUserOps.value && taskUserOps.value.length) {
        return taskUserOps.value.filter(item => {
          const userStr = item.fullName + "/" + item.email + item.fullName.replace(", ", "") + item.fullName.replace(", ", ",");
          return taskUserSelectList.value.indexOf(userStr) !== -1;
        });
      }
      return [];
    });
    const delUserClick = (record) => {
      const userStr = record.fullName + "/" + record.email + record.fullName.replace(", ", "") + record.fullName.replace(", ", ",");
      const index = taskUserSelectList.value.indexOf(userStr);
      taskUserSelectList.value.splice(index, 1);
    };
    const taskUserCols = [
      {
        title: "Name",
        dataIndex: "fullName",
        key: "fullName",
        width: 200,
        ellipsis: true,
        align: "left"
      },
      {
        title: "Email",
        dataIndex: "email",
        key: "email",
        width: 200,
        ellipsis: true,
        align: "left"
      },
      {
        title: "操作",
        dataIndex: "operation",
        slots: { customRender: "operation" },
        width: 100,
        fixed: "right"
      }
    ];

    const filterOption = (input, option) => {
      return (
        option.children[0].children
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    };

    const handleCancel = () => {
      emit("update:visible", false);
    };
    const handleSave = () => {
      if (!taskUserList.value || taskUserList.value.length === 0) {
        message.error("请选择人员")
        return
      }
      const idList = taskUserList.value.map(item => item.id);
      const params = {
        ...(props.task),
        userId: idList.join(",")
      };
      $api.addNewTask(params).then(() => {
        message.success("保存成功");
        emit("update:visible", false);
        emit("update");
      });
    };

    const overlapModalVisible = ref(false);
    const overlapData = ref({
      status: "prohibit",
      assignmentList: [],
      workTaskList: [],
      overlapCommandList: [],
      currentformData: {},
      demandList: [],
    });
    const selectUser = ref({});
    const isSelectDisable = ref(false)
    const annualAndOtherCode = ['12','13','06','07'];
    const handleUserSelect = async (selectVal) => {
      // 避免重复选择人员
      isSelectDisable.value = true

      selectUser.value = taskUserOps.value.find(item => {
        const userStr = item.fullName + "/" + item.email + item.fullName.replace(", ", "") + item.fullName.replace(", ", ",");
        return selectVal === userStr;
      })
      const params = {
        ...(props.task),
        userId: selectUser.value.id
      };
      try {
        const isOverlapRes = annualAndOtherCode.includes(props.task.taskType) ? await $api.specialLeaveOverlap(params):await $api.workTaskOverlap(params);
        isSelectDisable.value = false
        if(isOverlapRes.data.gatherFlag !== 'false'){
          overlapData.value = {
            status: isOverlapRes.data.gatherFlag,
            assignmentList: isOverlapRes.data.assignmentQueryPartCommandList || [],
            workTaskList: isOverlapRes.data.workTaskList || [],
            overlapCommandList: isOverlapRes.data.overlapCommandList || [],
            currentformData: {},
            demandList: isOverlapRes.data.demandList || [],
          };
          overlapModalVisible.value = true;
          return false;
        }
      }catch (e){
        delUserClick(selectUser.value)
        isSelectDisable.value = false
      }
    };
    onMounted(() => {
      getConsultantOptions();
    });

    return {
      taskUserSelectList,
      taskUserOps,
      taskUserList,
      delUserClick,
      taskUserCols,
      filterOption,
      handleCancel,
      handleSave,
      handleUserSelect,
      overlapData,
      overlapModalVisible,
      selectUser,
      isSelectDisable,
    };
  }
});
</script>

<style lang="less" scoped>
.task-user-list {
  background: #FFFFFF;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  padding: 24px;
  margin-top: 24px;

  .ant-table-wrapper {
    margin-top: 10px;
  }
}
.search{
  padding: 0 24px;
}
.add-task-select {
  width: calc(100% - 48px);
  margin: 8px 24px 0;
}
</style>

<style lang="less">
.add-task-select-option {
  .option {
    display: flex;

    .name {
      width: 50%;
    }

    .onboardDate {
      width: 25%;
    }

    .terminationDate {
      width: 25%;
    }
  }
}
</style>
