<template>
  <section class="app-container">
    <div class="mb12" v-if="!collapse">
      <div class="searchBox">
        <FilterLayout
          @doReset="resetForm"
          @doSearch="doSearch"
          @doCollapseEffect="getChartContainerStyle"
          ref="filterLayoutRef"
          @showMoreForm="showMoreForm"
          :moreBtnVisible="moreBtnVisible"
          :isShowMoreFlag="isShowMoreList"
        >
          <template #customform>
            <a-form
                ref="formRef"
                class="form rms-form"
                layout="vertical"
                :model="formState"
            >
              <template v-for="(item,index) in queryList" :key="index">
                <template v-if="index<=4">
                  <a-form-item :label="item.code" name="name" class="rms-form-item">
                    <template v-if="item.type==='2'">
                      <a-select
                          v-model:value="formState[item.inputDataType]"
                          :options="getOptions(item.inputDataType)"
                          placeholder="请选择"
                          allowClear
                          show-search
                      >
                      </a-select>
                    </template>
                    <template v-if="item.type==='3'">
                      <a-input
                          v-model:value="formState[item.inputDataType]"
                          placeholder="Name/GGID"
                          allowClear
                          autocomplete="off"
                      />
                    </template>
                    <template v-if="item.type==='5'">
                      <SelectWithAll
                          v-model:value="formState[item.inputDataType]"
                          :options="getOptions(item.inputDataType)"
                          placeholder="请选择"
                          mode="multiple"
                          showArrow
                          allowClear
                          @change="getSelectChange(item.inputDataType)"
                          :tag-count="item.inputDataType==='grade'?3:item.inputDataType==='taskStatus'?2:1"
                      >
                      </SelectWithAll>
                    </template>
                  </a-form-item>
                </template>
              </template>
              <MoreFormList :visible="isShowMoreList" @open="openFormSortBox" @close="closeMoreForm"  @moreFormSearch="moreSeachHanlder">
                <template v-for="(item,index) in queryList" :key="index">
                  <template v-if="index>4">
                    <a-form-item :label="item.code" name="name" class="rms-form-item">
                      <template v-if="item.type==='2'">
                        <a-select
                            v-model:value="formState[item.inputDataType]"
                            :options="getOptions(item.inputDataType)"
                            placeholder="请选择"
                            allowClear
                            show-search
                        >
                        </a-select>
                      </template>
                      <template v-if="item.type==='3'">
                        <a-input
                            v-model:value="formState[item.inputDataType]"
                            placeholder="Name/GGID"
                            allowClear
                            autocomplete="off"
                        />
                      </template>
                      <template v-if="item.type==='5'">
                        <SelectWithAll
                            v-model:value="formState[item.inputDataType]"
                            :options="getOptions(item.inputDataType)"
                            placeholder="请选择"
                            mode="multiple"
                            showArrow
                            allowClear
                            @change="getSelectChange(item.inputDataType)"
                            :tag-count="item.inputDataType==='grade'?3:item.inputDataType==='taskStatus'?2:1"
                        >
                        </SelectWithAll>
                      </template>
                    </a-form-item>
                  </template>
                </template>
              </MoreFormList>
            </a-form>
          </template>
          <template #operationBtns>
            <div class="buttons-wrap right">
              <Tooltip title="新增">
                <a-button
                    class="right"
                    type="primary"
                    @click="addClick"
                    v-auth="['system:task:add']"
                >
                  <PlusOutlined />
                </a-button>
              </Tooltip>
            </div>
          </template>
        </FilterLayout>
      </div>
    </div>
    <div class="table-box">
      <a-table
        :columns="columns"
        :data-source="tableList"
        rowKey="id"
        :scroll="{
          y: tableHeight,
          x: infoCollapse?2400:1800,
          scrollToFirstRowOnChange: true,
        }"
        :pagination="pagination"
      >
        <template #infoCollapse>
          <div class="collapse-cell">
            <div class="collapse-btn" @click="infoCollapse = !infoCollapse">
              <DoubleLeftOutlined v-if="infoCollapse" />
              <DoubleRightOutlined v-else />
            </div>
          </div>
        </template>
        <template #status="{ record }">
          <a-tag :class="`status ${record.taskStatusText}`">
            {{record.taskStatusText}}
          </a-tag>
        </template>
        <template #operation="{ record }">
          <a-space class="flex-box">
            <a-tooltip class="tooltip-list" placement="top" color="#fff">
              <template #title>
                <span class="font-color">{{ record.taskStatus === '0' || record.taskStatus === '1' || record.taskStatus === '2' ? '编辑':'查看' }}</span>
              </template>
              <span v-if="!(record.taskStatus === '0' || record.taskStatus === '1' || record.taskStatus === '2')" class="operationLink underline icon-btn" @click="viewClick(record)"
              ><EyeOutlined :style="{display:'block'}" /></span>
              <span
                  class="operationLink underline icon-btn"
                  v-if="record.taskStatus === '0' || record.taskStatus === '1' || record.taskStatus === '2' "
                  @click="editClick(record)"
                  v-auth="['system:task:edit']"
              ><FormOutlined :style="{display:'block'}" /></span>
            </a-tooltip>
            <a-tooltip class="tooltip-list" placement="top" color="#fff">
              <template #title>
                <span class="font-color">失效</span>
              </template>
              <span
                  class="redLink underline icon-btn"
                  :class="record.taskStatus === '0'?'':'disable'"
                  @click="invalidClick(record)"
                  v-auth="['system:task:invalid']"
              ><MinusCircleOutlined :style="{display:'block'}" /></span>
            </a-tooltip>
            <a-tooltip class="tooltip-list" placement="top" color="#fff">
              <template #title>
                <span class="font-color">记录</span>
              </template>
              <span
                  class="operationLink underline icon-btn"
                  @click="recordClick(record)"
                  v-auth="['system:task:record']"
              ><HistoryOutlined :style="{display:'block'}" /></span>
            </a-tooltip>
          </a-space>
        </template>
      </a-table>
    </div>
    <TaskDrawer
      v-if="taskDrawerVisible"
      :title="taskTitle"
      :task="taskData"
      :user="taskUserData"
      :userId="taskUserId"
      @update="doSearch"
      :readonly="taskReadonly"
      v-model:visible="taskDrawerVisible"
    >
    </TaskDrawer>
    <ChangeRecordModal
      v-model:visible="recordVisible"
      title="查看记录"
      @close-modal="closeRecordModal"
      :id="taskData ? taskData.id : ''"
      :info="taskData"
    />
    <FormModalBox v-model:visible="isShowFormSortBox" :sortFlag="true" :checkList="checkList" @closeModal="closeModal" @reset="queryFormList"></FormModalBox>
  </section>
  <ModalBox v-model="modelValue" :ok="handleOk" :content="content" :showIconCom="showIconCom" />
</template>

<script>
import {
  defineComponent,
  onUnmounted,
  reactive,
  ref,
  nextTick,
  onActivated,
  onMounted,
  computed,
  watch,
} from "vue";
import { useGlobalPropertyHook } from "@/hooks/common.js";
import {dictionaryOpt, dictionaryOptionsCode, useTableHeight} from "@/utils/common";
import {
  DoubleLeftOutlined,
  DoubleRightOutlined,
  PlusOutlined,
  EyeOutlined,
  FormOutlined,
  MinusCircleOutlined,
  HistoryOutlined
} from "@ant-design/icons-vue";
import { message } from "ant-design-vue";
// import TaskDrawer from "../RM/components/TaskDrawer";
import ChangeRecordModal from "@/components/ChangeRecordModal";
import { onBeforeRouteLeave, useRoute } from "vue-router";
import { useStore } from "vuex";
import TaskDrawer from "./components/TaskDrawer";
import ModalBox from '@/components/ModalBox'
import MoreFormList from '@/components/MoreFormList'
import FormModalBox from '@/components/FormModalBox'
import Tooltip from '@/components/Tooltip'
import {debounce} from 'lodash'
export default defineComponent({
  name: "resourceTask",
  components: {
    Tooltip,
    DoubleLeftOutlined,
    DoubleRightOutlined,
    TaskDrawer,
    ChangeRecordModal,
    PlusOutlined,
    ModalBox,
    EyeOutlined,
    FormOutlined,
    MinusCircleOutlined,
    HistoryOutlined,
    MoreFormList,
    FormModalBox
  },
  setup() {
    const route = useRoute();
    const store = useStore();

    const isRM = computed(() => {
      const roles = store.state.app.user.roles;
      return roles.indexOf("2") !== -1;
    });

    const isRMLeader = computed(() => {
      const roles = store.state.app.user.roles;
      return roles.indexOf("11") !== -1;
    });
    const collapse = ref(false);
    const staffGradeOps = ref([]);
    const orgThreeTwoOps = ref([]);
    const orgFourOneOneOps = ref([]);
    const taskTypeOps = ref([]);
    const taskStatusOps = ref([]);
    const positionStatusOps = ref([]);
    const formState = ref({
      condition: "",
      grade: [],
      orgThreeTwo: [],
      orgFourOneOne: [],
      taskType: [],
      taskStatus: [],
      jobStatus: "",
      taskChannel:undefined,
    });
    const getOrgList = () => {
      //查询org3.2/org4.2
      $api.getOrgThree_FourList().then((res) => {
        const { orgThreeTwo, orgFourOneOne } = res.data;
        orgThreeTwoOps.value = dictionaryOpt(orgThreeTwo);
        orgFourOneOneOps.value = dictionaryOpt(orgFourOneOne);
      });
    };
    const orgThreeTwoChange = () => {
      formState.value.orgFourOneOne = [];
      if (formState.value.orgThreeTwo.length) {
        $api
          .getOrgFour({ condition: formState.value.orgThreeTwo.join() })
          .then((res) => {
            orgFourOneOneOps.value = dictionaryOpt(res.data.orgFourOneOne);
          });
      } else {
        getOrgList();
      }
      // doSearch();
    };

    const infoCollapse = ref(false);
    const collapseCellFunc = () => {
      return {
        style: {
          "background-color": "#F3F3F3",
        },
      };
    };
    const collapseTitleCellFunc = () => {
      return {
        style: {
          "background-color": "#FBFBFB",
        },
      };
    };
    const columns = computed(() => {
      let infoCols = [];
      if (infoCollapse.value) {
        infoCols = [
          {
            title: "Team",
            dataIndex: "orgThreeTwo",
            key: "orgThreeTwo",
            customHeaderCell: collapseCellFunc,
            customCell: collapseTitleCellFunc,
            ellipsis: true,
            width: 130,
          },
          {
            title: "Offering",
            dataIndex: "orgFourOneOne",
            key: "orgFourOneOne",
            customHeaderCell: collapseCellFunc,
            customCell: collapseTitleCellFunc,
            ellipsis: true,
            width: 230,
          },
          {
            title: "Grade",
            dataIndex: "psGroup",
            key: "psGroup",
            customHeaderCell: collapseCellFunc,
            customCell: collapseTitleCellFunc,
            ellipsis: true,
            width: 100,
          },
          {
            title: "GGID",
            dataIndex: "ggid",
            key: "ggid",
            customHeaderCell: collapseCellFunc,
            customCell: collapseTitleCellFunc,
            ellipsis: true,
            width: 160,
          },
        ];
      }
      let operationColums = [];
      if (isRMLeader.value || isRM.value) {
        operationColums.push({
          title: "操作",
          dataIndex: "operation",
          slots: { customRender: "operation" },
          width: 140,
          fixed: "right",
        });
      }
      return [
        {
          title: "姓名",
          dataIndex: "fullName",
          key: "fullName",
          width: 160,
          ellipsis: true,
          fixed: "left",
        },
        {
          dataIndex: "infoCollapse",
          key: "infoCollapse",
          width: 0,
          slots: { title: "infoCollapse" },
          fixed: "left",
        },
        ...infoCols,
        {
          title: "Task Type",
          dataIndex: "taskTypeText",
          key: "taskTypeText",
          ellipsis: true,
          width: 180,
        },
        {
          title: "Task Detail",
          dataIndex: "taskDetailText",
          key: "taskDetailText",
          ellipsis: true,
          width: 180,
        },
        {
          title: "Task状态",
          dataIndex: "taskStatusText",
          key: "taskStatusText",
          width: 100,
          ellipsis: true,
          slots: { customRender: "status" },
        },
        {
          title: "开始时间",
          dataIndex: "beginDate",
          key: "beginDate",
          ellipsis: true,
          width: 200,
        },
        {
          title: "结束时间",
          dataIndex: "endDate",
          key: "endDate",
          ellipsis: true,
          width: 200,
        },
        {
          title: "小时数",
          dataIndex: "hours",
          key: "hours",
          width: 100,
          ellipsis: true,
        },
        {
          title: "Total Hours",
          dataIndex: "totalHours",
          key: "totalHours",
          ellipsis: true,
          width: 150,
        },
        {
          title: "Project Code",
          dataIndex: "projectCode",
          key: "projectCode",
          width: 150,
          ellipsis: true,
        },
        {
          title: "PM",
          dataIndex: "pmName",
          key: "pmName",
          ellipsis: true,
        },
        {
          title: "Task Channel",
          dataIndex: "taskChannel",
          key: "taskChannel",
          width: 170,
          ellipsis: true,
        },
        // {
        //   title: "Review Operator",
        //   dataIndex: "reviewOperator",
        //   key: "reviewOperator",
        //   width: 170,
        //   ellipsis: true,
        // },
        // {
        //   title: "Review Status",
        //   dataIndex: "reviewStatus",
        //   key: "reviewStatus",
        //   width: 170,
        //   ellipsis: true,
        // },
        // {
        //   title: "Review Time",
        //   dataIndex: "reviewTime",
        //   key: "reviewTime",
        //   width: 220,
        //   ellipsis: true,
        // },
        ...operationColums,
      ];
    });
    const tableList = ref([]);
    const pagination = reactive({
      current: 1,
      pageSize: 12,
      pageSizeOptions: ['12','20','30','40'],
      total: 0,
      showSizeChanger: true,
      showTotal: (total) => {
        return `共${total}条`;
      },
      onChange: (page, pageSize) => {
        pagination.current = page;
        pagination.pageSize = pageSize;
        getTableList();
      },
      onShowSizeChange: (_, size) => {
        pagination.current = 1;
        pagination.pageSize = size;
        getTableList();
      },
    });
    const tableHeight = ref(0);
    const tableWidth = ref(0);
    const visible = ref(false);
    const activeRow = ref({});
    const activeIndex = ref();

    let currentParams = {};
    //只有在点击查询按钮时才更新筛选项
    const updateParams = () => {
      currentParams = {
        condition: formState.value.condition,
        grade: formState.value.grade,
        orgFourOneOne: formState.value.orgFourOneOne,
        orgThreeTwo: formState.value.orgThreeTwo,
        taskStatus: formState.value.taskStatus,
        taskType: formState.value.taskType,
        jobStatus: formState.value.jobStatus,
        pageIndex: pagination.current,
        pageSize: pagination.pageSize,
        taskId: formState.value.taskId,
        taskChannel:formState.value.taskChannel
      };
    };

    // 获取筛选框字典
    const getSelectOptions = () => {
      $api
        .getDictionaryList({
          params: {
            dictType: "grade,taskStatus,taskType,jobStatus",
          },
        })
        .then((res) => {
          staffGradeOps.value = dictionaryOptionsCode(res.data.grade);
          taskStatusOps.value = dictionaryOptionsCode(res.data.taskStatus);
          positionStatusOps.value = dictionaryOptionsCode(res.data.jobStatus);
          if (isRM.value || isRMLeader.value) {
            taskTypeOps.value = dictionaryOptionsCode(res.data.taskType);
          } else {
            const taskTypeList = res.data.taskType.filter(
              (item) => item.dictCode !== "06"
            );
            taskTypeOps.value = dictionaryOptionsCode(taskTypeList);
          }
        });
    };
    const formatTime = (dateTimeString) => {
      let formattedDate = null
      if(dateTimeString){
        const date = new Date(dateTimeString);
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0'); // 月份从0开始，因此要加1
        const day = date.getDate().toString().padStart(2, '0');
        formattedDate = `${year}-${month}-${day}`;

        // 如果时间部分存在，则添加时间部分
        if (!isNaN(date.getTime())) {
          const hours = date.getHours().toString().padStart(2, '0');
          const minutes = date.getMinutes().toString().padStart(2, '0');
          const seconds = date.getSeconds().toString().padStart(2, '0');
          formattedDate += ` ${hours}:${minutes}:${seconds}`;
        }
      }
      return formattedDate;
    }
    const { $api } = useGlobalPropertyHook();
    const doSearch = () => {
      pagination.current = 1;
      updateParams();
      getTableList();
    };
    const getTableList = () => {
      const urlParams = new URLSearchParams(window.location.search);
      const taskIdFromUrl = urlParams.get("id");

      // 更新 formState 中的 taskId
      formState.value.taskId = taskIdFromUrl;
      const data = {
        ...currentParams,
        pageIndex: pagination.current,
        pageSize: pagination.pageSize,
        taskId:taskIdFromUrl
      };

      $api.getTaskList(data).then((res) => {
        tableList.value = res.data.resource.map((item)=>{
          return {
            ...item,
            reviewTime:formatTime(item.reviewTime)
          }
        });
        pagination.total = res.data.total;
        pagination.current = res.data.pageIndex + 1;
        pagination.pageSize = res.data.pageSize;
        formState.value.taskId = "";
      });
    };
    // 列表内容、分页、筛选项重置
    const resetForm = () => {
      formState.value = {
        condition: "",
        grade: [],
        orgThreeTwo: [],
        orgFourOneOne: [],
        taskType: [],
        taskStatus: ["0", "1"],
        jobStatus: "1",
        taskId: "",
        taskChannel:undefined
      };
        // 清除 URL 中的 id 参数
      const urlParams = new URLSearchParams(window.location.search);
        if (urlParams.has("id")) {
          urlParams.delete("id");
          const newUrl = `${window.location.pathname}?${urlParams.toString()}`;
          window.history.replaceState({}, "", newUrl);
        }
      tableList.value = [];
      pagination.pageSize = 12;
      pagination.current = 1;
      pagination.total = 0;
      updateParams();
      getTableList();
      if (isRM.value || isRMLeader.value) {
        getOrgList();
      }
    };

    const updateClick = (record, index) => {
      const params = {
        arveNo: record.arveNO,
        bt: record.btNbtWwocFunding,
        beginDate: record.beginDate,
        endDate: record.endDate,
        userId: record.userId,
      };
      $api.calculateFCST(params).then((res) => {
        visible.value = true;
        record.btFcst = res.data.btFcst;
        record.btGap = res.data.btGap;
        activeRow.value = record;
        activeIndex.value = index;
      });
    };

    const closeDrawer = () => {
      visible.value = false;
    };

    const getChartContainerStyle = () => {
      // collapse.value = !collapse.value;
      getSize();
    };
    const moreSeachHanlder = () => {
      doSearch()
      closeMoreForm()
    }
    const taskData = ref();
    const taskUserData = ref();
    const taskUserId = ref();
    const taskUserIdList = ref([]);
    const taskTitle = ref("");
    const taskDrawerVisible = ref(false);
    const taskReadonly = ref(false);
    const taskAdd = ref(false);
    const addClick = () => {
      taskData.value = undefined;
      taskUserData.value = undefined;
      taskUserId.value = undefined;
      taskUserIdList.value = [];
      taskTitle.value = "添加任务";
      taskDrawerVisible.value = true;
      taskReadonly.value = false;
      taskAdd.value = true;
    };
    const viewClick = (record) => {
      taskData.value = record;
      taskUserData.value = record;
      taskUserId.value = record.userId;
      taskTitle.value = "查看任务";
      taskDrawerVisible.value = true;
      taskReadonly.value = true;
      taskAdd.value = false;
    };
    const editClick = (record) => {
      taskData.value = record;
      taskUserData.value = record;
      taskUserId.value = record.userId;
      taskTitle.value = "编辑任务";
      taskDrawerVisible.value = true;
      taskReadonly.value = false;
      taskAdd.value = false;
    };
    const modelValue = ref(false)
    const content = ref('')
    const showIconCom = ref('')
    const modalData = ref()
    const handleOk = () => {
      const {record} = modalData.value
      $api.invalidTask({ taskId: record.id }).then(() => {
        message.success("失效成功");
        getTableList();
      });
    }
    const invalidClick = (record) => {
      if(record.taskStatus !== '0'){
        return false
      }
      content.value = '是否失效?'
      showIconCom.value = 'ExclamationCircleOutlined'
      modalData.value = {record}
      modelValue.value = true

      /*Modal.confirm({
        title: "是否失效?",
        icon: createVNode(ExclamationCircleOutlined),
        okText: "确定",
        cancelText: "取消",
        centered: true,
        onOk() {
          $api.invalidTask({ taskId: record.id }).then(() => {
            message.success("失效成功");
            getTableList();
          });
        },
      });*/
    };
    const recordVisible = ref(false);
    const recordClick = (record) => {
      taskData.value = record;
      recordVisible.value = true;
    };
    const closeRecordModal = () => {
      recordVisible.value = false;
    };
    const getSize = () => {
      nextTick(() => {
        const bodyWidth = document.body.clientWidth;
        tableWidth.value = bodyWidth - (93 / 1920) * bodyWidth;
        tableHeight.value = useTableHeight();
      });
    };

    window.addEventListener("resize", getSize);

    const queryList = ref([])
    const checkList = ref([])
    const moreBtnVisible = ref(false)
    const queryFormList = (data) => {
      $api.queryFilterSort({
        firstMenu: 'resourceManagement',
        secondMenu: 'taskManagement',
        isQueryDefault: data.isQueryDefault
      }).then((res) => {
        if(data.isQuery){
          queryList.value = res.data.filter((item)=>{
            return item.isDeleted==='0'
          })
          queryList.value.length > 5?moreBtnVisible.value = true :moreBtnVisible.value = false
          checkList.value=[...queryList.value]
        }else{
          checkList.value = res.data.filter((item)=>{
            return item.isDeleted==='0'
          })
        }
      });
    };
    const taskChannelOps = [
      {
        label:'Hand in Hand',
        value:'Hand in Hand',
        key:'Hand in Hand',
      },
      {
        label:'RMS',
        value:'RMS',
        key:'RMS',
      },
    ]
    const getOptions = (data)=> {
      switch (data) {
        case 'grade':
          return staffGradeOps.value
        case 'orgThreeTwo':
          return orgThreeTwoOps.value
        case 'orgFourOneOne':
          return orgFourOneOneOps.value
        case 'taskStatus':
          return taskStatusOps.value
        case 'taskType':
          return taskTypeOps.value
        case 'jobStatus':
          return positionStatusOps.value
        case 'taskChannel':
          return taskChannelOps
        default :
          ''  // 如果没
      }
    }
    const getSelectChange = debounce((data)=> {
      switch (data) {
        case 'orgThreeTwo':
          return orgThreeTwoChange()
        default :
          ''  // 如果没
      }
    },500)
    const isShowMoreList = ref(false)
    const isShowFormSortBox = ref(false)
    const showMoreForm = (data) => {
      isShowMoreList.value = data
    }
    const openFormSortBox = () => {
      isShowFormSortBox.value = true
    }
    const closeMoreForm = (data) => {
      if(data){
        console.log(123)
      }
      isShowMoreList.value = false
    }
    const closeModal = ()=> {
      isShowFormSortBox.value = false
    }
    onMounted(() => {
      getSelectOptions();
      updateParams();
      if (isRM.value || isRMLeader.value) {
        getOrgList();
      }
      setTimeout(queryFormList({isQueryDefault:0,isQuery: true}),500)
    });

    onActivated(() => {
      if (route.query.idNumber) {
        formState.value = {
          condition: route.query.idNumber,
          grade: [],
          orgThreeTwo: [],
          orgFourOneOne: [],
          taskType: [],
          taskStatus: ["0", "1"],
          jobStatus: route.query.jobStatus,
          taskChannel:undefined
        };
      }
      // if (route.query.id) {
      //   formState.value = {
      //     taskId:route.query.id,
      //     fullName: '',
      //     grade: [],
      //     orgThreeTwo: [],
      //     orgFourOneOne: [],
      //     taskType: [],
      //     taskStatus: [],
      //     jobStatus: route.query.jobStatus,
      //   };
      // }
      if (isRM.value || isRMLeader.value) {
        updateParams();
        getSize();
        getTableList();
      }
    });
    watch(
      () => route.query.id,
      () => {
        formState.value = {
          taskId: route.query.id,
          condition: "",
          grade: [],
          orgThreeTwo: [],
          orgFourOneOne: [],
          taskType: [],
          taskStatus: [],
          jobStatus: route.query.jobStatus,
          taskChannel:undefined
        };
        if (isRM.value || isRMLeader.value) {
          updateParams();
          getSize();
          getTableList();
        }
      }
    );
    onBeforeRouteLeave(() => {
      pagination.current = 1;
    });
    onUnmounted(() => {
      window.removeEventListener("resize", getSize);
    });

    return {
      collapse,
      staffGradeOps,
      taskTypeOps,
      taskStatusOps,
      positionStatusOps,
      formState,
      resetForm,
      infoCollapse,
      columns,
      tableList,
      pagination,
      tableHeight,
      tableWidth,
      getTableList,
      doSearch,
      visible,
      updateClick,
      activeRow,
      activeIndex,
      closeDrawer,

      orgThreeTwoOps,
      orgFourOneOneOps,
      orgThreeTwoChange,
      taskData,
      taskUserData,
      taskUserId,
      taskTitle,
      taskDrawerVisible,
      taskReadonly,
      taskAdd,
      addClick,
      editClick,
      viewClick,
      invalidClick,
      recordClick,
      closeRecordModal,
      recordVisible,
      updateParams,
      isRMLeader,
      isRM,
      getChartContainerStyle,
      modelValue,
      content,
      showIconCom,
      handleOk,
      queryFormList,
      queryList,
      checkList,
      getOptions,
      getSelectChange,
      isShowMoreList,
      showMoreForm,
      moreBtnVisible,
      openFormSortBox,
      isShowFormSortBox,
      closeMoreForm,
      closeModal,
      moreSeachHanlder,
    };
  },
});
</script>

<style lang="less" scoped>
.table-box {
  position: relative;
  .batch-btn {
    position: absolute;
    left: 0;
    bottom: 10px;
  }
  .status{
    font-size: 0.729vw;
    padding: 0.1vw 0.86vw;
    border: 0;

    &.未开始 {
      color: #1890FF;
      background: #DBEEFF;
    }
    &.进行中 {
      color: #58B99E;
      background: #DDF8F0 60%;
    }
    &.已完成 {
      color: #999999;
      background: #CCCCCC 60%;
    }
    &.已失效 {
      color: #DC5E4F;
      background: #FFE7EC;
    }
  }
}

.collapse-cell {
  position: relative;
}

.collapse-btn {
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: #d0e2f3;
  color: #3182ce;
  position: absolute;
  left: -28px;
}
.flex-box{
  display: flex;
  .icon-btn {
    display: flex;
    width: 24px;
    height: 24px;
    :deep(svg) {
      width: 100%;
      height: 100%;
    }
  }
}

.font-color{
  color:#4d4d4d;
}

:deep(.disable){
  cursor: auto;
  .anticon{
    color: #999 !important;
    svg{
      color: #999 !important;
    }
  }
}
</style>
